import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: page non trouvée" />
    <div className="container mt">
      <div className="row">
        <div className="column-left">
        </div>
        <div className="column-right pt">
          <h1>page non trouvée</h1>
          <p>Cette page n'existe pas ou plus…</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
